<template>
  <div id="layout" :class="htmlClass" v-if="loaded">
    <div
      style="min-height: calc(100vh - 105px)"
      class="fade-in"
      id="main-content"
      :class="{ 'faded-in': fadeIn == true }"
    >
      <slot />
    </div>
    <b-navbar
      toggleable="lg"
      type="light"
      variant="light"
      id="main-nav"
      :class="{ 'faded-in': fadeIn == true }"
      class="py-3 border-botom border fade-in"
    >
      <NuxtLink
        to="/"
        id="brand"
        style="float: left; position: relative; left: 0px; top: 0px"
        class="text-dark nav-branding d-inline-flex"
      >
        <b-img
          :src="config.logo || '/images/eye.png'"
          style="
            width: 20px;
            height: 20px;
            margin-right: 12px;
            border-radius: 2px;
          "
        />
        <b-icon-eye
          v-if="false"
          class="mr-3 ml-1 d-inline"
          font-size="18"
          style="margin-top: 0.5px"
        ></b-icon-eye>
        <span>
          <span class="navbar-brand-text">
            <span>{{ config.siteName }}</span></span
          ><span style="opacity: 0.3" class="d-none">.com</span>
          <span class="d-inline d-lg-none">
            <span v-if="currentRoute().includes('prompts')">Prompts</span>
            <span v-if="currentRoute().includes('docs')">Documents</span>
            <span v-if="currentRoute().includes('images')">Images</span>
            <span v-if="currentRoute().includes('text-to-speech')">Speech</span>
          </span>
        </span>
        <WorkspaceNav
          class="d-none d-lg-inline-block"
          v-if="user != null && user.email != null"
        ></WorkspaceNav>
        <div v-else>
          <div class="d-none d-lg-inline-block" style="height: 35px">
            &nbsp;
          </div>
        </div>
      </NuxtLink>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          &nbsp;
          <b-nav-item
            href="/prompts"
            class="ml-2 mr-2"
            style="min-width: 120px"
            :class="{
              active: currentRoute().includes('prompts'),
              raised: currentRoute().includes('prompts'),
            }"
            ><b-icon-code-square font-scale=".9"></b-icon-code-square
            >Prompts</b-nav-item
          >
          <b-nav-item
            href="/docs"
            class="ml-2 mr-2"
            style="min-width: 120px"
            :class="{
              active: currentRoute().includes('docs'),
              raised: currentRoute().includes('docs'),
            }"
            ><b-icon-file-earmark-text
              font-scale=".9"
            ></b-icon-file-earmark-text
            >Documents</b-nav-item
          >
          <b-nav-item
            href="/images"
            class="mx-2"
            :class="{
              active: currentRoute().includes('images'),
              raised: currentRoute().includes('images'),
            }"
            style="min-width: 110px"
            ><b-icon-image font-scale=".95"></b-icon-image>Images</b-nav-item
          >
          <b-nav-item
            v-if="false"
            href="/deepfakes"
            :disabled="currentRoute() == 'deepfakes'"
            class="mx-2"
            style="min-width: 100px"
            ><b-icon-person-square font-scale=".95"></b-icon-person-square
            >Deepfakes</b-nav-item
          >
          <b-nav-item
            v-if="true == true || hasPermission('tts')"
            href="/text-to-speech"
            class="mx-2"
            style="min-width: 100px"
            :class="{
              active: currentRoute().includes('text-to-speech'),
              raised: currentRoute().includes('text-to-speech'),
            }"
            ><b-icon-soundwave font-scale=".95"></b-icon-soundwave
            >Speech</b-nav-item
          >
          <b-nav-item
            v-if="user == null || user.email == null"
            href="/pricing"
            :disabled="currentRoute() == 'pricing'"
            class="mx-2"
            style="min-width: 90px"
            ><b-icon-credit-card font-scale=".95"></b-icon-credit-card
            >Pricing</b-nav-item
          >
          <b-nav-item
            v-if="true"
            href="/help"
            class="mx-2"
            style="min-width: 90px"
            ><b-icon-journal-code font-scale=".95"></b-icon-journal-code
            >Help</b-nav-item
          >
        </b-navbar-nav>
      </b-collapse>
      <div
        v-if="user != null && user.email != null"
        id="darkmode-toggle-button"
        style="
          float: right;
          position: absolute;
          right: 85px;
          top: 22px;
          cursor: pointer;
        "
        @click="toggleDarkMode()"
      >
        <b-icon-sun></b-icon-sun>
      </div>
      <MobileNav class="pull-right mobile-nav-container"></MobileNav>
    </b-navbar>
    <div v-if="false">
      <div
        class="w-100 footer-border"
        :class="{ 'bg-none': currentRoute() != 'index' }"
        v-if="
          options == null || (options != null && options?.hideFooter != true)
        "
      ></div>
      <div
        v-if="options?.hideFooter != true && currentRoute() == 'index'"
        class="w-100 d-block bg-none site-footer d-flex text-left border-none"
        style="position: relative; bottom: 0px; left: 0px"
      >
        <SiteFooter />
      </div>
    </div>
    <WorkspaceEditor></WorkspaceEditor>
    <SupportChat :user="user"></SupportChat>
  </div>
</template>

<style>
body,
html {
  overflow-x: hidden !important;
  overflow-y: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
@media (min-width: 991px) {
  ::-webkit-scrollbar {
    display: none;
  }
}

body,
html {
  background: #fff !important;
}
.modal-open #layout > *:not(.modal) {
  transition: opacity 0.3s;
}
.modal-open #layout > *:not(.modal) {
  opacity: 0.25;
}

.modal-dialog {
  opacity: 0;
}

.modal-open .modal-dialog {
  opacity: 1;
  box-shadow:
    0px 25px 25px rgba(0, 0, 0, 0.125),
    0px 50px 50px rgba(0, 50, 150, 0.25) !important;
  border-radius: 5px;
}

#main-content {
  z-index: 0 !important;
}

.monochrome > *:not(.monochrome-disabled) {
  filter: brightness(0.8) contrast(1.1) brightness(1.1) sepia(0.8) saturate(0)
    hue-rotate(60deg) !important;
}

.footer-border {
  background: linear-gradient(
    90deg,
    rgba(10, 0, 74, 0.177),
    rgba(255, 255, 255, 0) 66%
  ) !important;
  height: 1px;
}

.dark .footer-border {
  margin-top: 0px !important;
}

.bg-light-red {
  background: #ffd7d7 !important;
}

.text-light-red {
  color: #dc3545;
}

.border-green {
  border: 1px solid rgb(14, 166, 133) !important;
}

.bg-very-light-green {
  background: rgb(224, 250, 241) !important;
}

.icon-green svg {
  color: rgb(14, 166, 133) !important;
}

.icon-blue svg {
  color: #007bff important;
}

.bg-light-green {
  background: rgb(191, 240, 223) !important;
}

.text-light-green {
  color: rgb(14, 166, 133) !important;
}

.bg-light-blue {
  background: rgb(214 239 255) !important;
}

.bg-light-blue.active {
  background: rgb(210 231 255) !important;
}

.text-light-blue {
  color: rgb(28, 159, 247) !important;
}

.dark #darkmode-toggle-button {
  color: #85bcff !important;
}

.light .btn-outline-light {
  border-color: #000 !important;
  color: #000 !important;
}
.light .btn-outline-light:hover {
  border-color: #fff !important;
  color: #fff !important;
  background: #000 !important;
}

.prompt-option {
  cursor: pointer;
  transition: all 0.3s;
}

.prompt-option:hover {
  box-shadow: 0px 5px 15px rgba(0, 100, 150, 0.2) !important;
}

.prompt-option.active,
.list-group-item.active {
  background: linear-gradient(90deg, rgb(239, 246, 255), #fff) !important;
  color: royalblue !important;
}

.raised {
  box-shadow: rgb(0 50 100 / 7%) 0px 10px 15px 10px !important;
}

.br-5 {
  border-radius: 5px;
  overflow: hidden;
}
.br-10 {
  border-radius: 10px;
  overflow: hidden;
}
.br-15 {
  border-radius: 15px;
  overflow: hidden;
}
.br-20 {
  border-radius: 20px;
  overflow: hidden;
}
.br-25 {
  border-radius: 25px;
  overflow: hidden;
}

.heading {
  background-size: contain !important;
  background: linear-gradient(
    0deg,
    #ff5d00 15%,
    #ff0087 30%,
    #5500b2 85%
  ) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.navbar .workspace-dropdown {
  max-height: 35px;
}

.workspace-dropdown,
.workspace-dropdown .dropdown-menu {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  list-style: none !important;
  text-transform: none !important;
  font-size: 100% !important;
}

.dark .navbar .workspace-dropdown .text-dark,
.dark .navbar .workspace-dropdown .svg {
  color: #fff !important;
  fill: #fff !important;
}

.workspace-dropdown .dropdown-toggle::after {
  margin-left: 10px !important;
  display: none !important;
  opacity: 0 !important;
}

.modal-backdrop {
  background: linear-gradient(
    rgba(0, 0, 0, 0) 0px,
    rgba(0, 50, 150, 0.66) 70px,
    rgba(0, 0, 0, 0) 90%
  ) !important;
}

.toasted-primary {
  min-height: 40px !important;
  border-radius: 5px !important;
  background: -webkit-linear-gradient(
    -120deg,
    #363636 10%,
    #222 90%
  ) !important;
  color: #fff !important;
  font-weight: 600;
  padding-left: 25px !important;
  padding-right: 25px !important;
  backdrop-filter: blur(30px) !important;
  box-shadow: rgb(0 50 100 / 7%) 0px 10px 35px 10px !important;
}

@media (max-width: 991px) {
  .toasted-primary {
    min-height: 70px !important;
    border-radius: 0px !important;
    background: #fff !important;
    color: black !important;
    backdrop-filter: blur(30px) !important;
    box-shadow: rgb(0 50 100 / 7%) 0px 10px 35px 10px !important;
  }
}

input::placeholder {
  opacity: 0.5 !important;
}

.btn-outline-primary:not(.active):hover {
  color: royalblue !important;
  background: rgb(243, 244, 253) !important;
}

.btn-light {
  background: #fff !important;
}

.btn-light.disabled,
.btn-light:disabled,
.bg-light.disabled,
.bg-light:disabled {
  color: rgba(0, 0, 0, 0.3) !important;
  background: #fff !important;
  cursor: not-allowed !important;
}

.btn.disabled,
button.disabled {
  opacity: 1 !important;
  pointer-events: none !important;
  cursor: not-allowed !important;
}

.btn-outline-primary,
.btn.border-primary,
.btn.border-primary.disabled {
  border-color: #007bff !important;
}

.btn-light.disabled:hover {
  color: rgba(0, 0, 0, 0.3) !important;
  background: #fff !important;
}

button.bg-light:hover {
  background-color: unset !important;
}

html.dark {
  background: #000 !important;
}

.dark .btn-outline-dark {
  border: 1px solid #fff !important;
  color: #fff !important;
}

.dark .btn-outline-dark:hover {
  border: 1px solid #fff !important;
  color: #000 !important;
  background: #fff !important;
}

.plyr--audio .plyr__controls {
  background: #fff !important;
}

li.menu-button:hover {
  background: #e9ebf1 !important;
}
a.dropdown-item:active {
  background: #e9ebf1 !important;
}

.opacity-0,
.opacity-0.disabled {
  opacity: 0 !important;
}

.opacity-10,
.opacity-10.disabled {
  opacity: 0.1 !important;
}
.opacity-20,
.opacity-20.disabled {
  opacity: 0.2 !important;
}
.opacity-30,
.opacity-30.disabled {
  opacity: 0.3 !important;
}
.opacity-40,
.opacity-40.disabled {
  opacity: 0.4 !important;
}
.opacity-50,
.opacity-50.disabled {
  opacity: 0.5 !important;
}
.opacity-0,
.opacity-0.disabled {
  opacity: 0.6 !important;
}
.opacity-70,
.opacity-70.disabled {
  opacity: 0.7 !important;
}
.opacity-80,
.opacity-80.disabled {
  opacity: 0.8 !important;
}
.opacity-90,
.opacity-90.disabled {
  opacity: 0.9 !important;
}
.opacity-100,
.opacity-100.disabled {
  opacity: 1 !important;
}

.bg-none {
  background: none !important;
}
</style>

<style scoped>
#layout {
  touch-action: manipulation !important;
  min-height: calc(100vh + 70px);
  margin-top: -35px;
  padding-top: 35px;
  background-color: #fff !important;
  background: linear-gradient(#fffffb 0px, rgba(255, 255, 255, 0) 35px),
    linear-gradient(rgba(181, 204, 252, 0.54902), #ffffff 800px),
    linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.47843),
      #fcb5b5,
      rgba(255, 255, 255, 0.47843)
    ) !important;
}

@media (min-width: 991px) {
  #brand {
    top: 7.5px !important;
  }
}

#main-nav {
  position: fixed;
  top: 0px !important;
  left: 0px;
  width: 100%;
  backdrop-filter: blur(30px);
  background: linear-gradient(#ffffff, rgba(255, 255, 255, 0.5)) !important;
  border: none !important;
  box-shadow: 0px 10px 35px 10px rgb(0 50 100 / 10%);
  z-index: 10 !important;
}

.dark .navbar-page-title {
  color: rgba(200, 215, 255, 0.9) !important;
}

#layout.dark {
  background: linear-gradient(0deg, #000000 -5px, #00040f 500px) !important;
}
#layout.dark #main-nav {
  background: linear-gradient(180deg, #000, #000c17 100%) !important;
  border-bottom: 1px solid rgba(100, 150, 255, 0.3) !important;
  box-shadow: none !important;
}

.nav-branding {
  color: #434b7d !important;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 80%;
  margin-right: 5px;
  text-decoration: none !important;
}

.navbar-brand-text {
  background: -webkit-linear-gradient(
    180deg,
    #999fbb 10%,
    #000000 90%
  ) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

#layout.dark .navbar-brand-text {
  background: -webkit-linear-gradient(
    180deg,
    #566378 10%,
    #999fbb 90%
  ) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.navbar-nav {
  font-size: 80%;
}
.navbar-nav .nav-item {
  display: inline-block !important;
}
.navbar-nav .nav-item,
.navbar-nav .nav-item a {
  transition: all 0.3s;
  border-radius: 8px;
  text-align: center;
}

.navbar-nav .nav-item a {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.navbar-nav .nav-item svg {
  opacity: 0.5;
  margin-right: 7px;
  position: relative;
  top: -1px !important;
  display:inline-block;
}

.navbar-nav .nav-item a.disabled {
  color: #434b7d;
  box-shadow: 0px 7px 7px rgba(0, 50, 150, 0.1);
}

.navbar-nav .nav-item:hover a {
  color: #434b7d;
  box-shadow: 0px 7px 7px rgba(0, 50, 150, 0.1);
}

#layout.dark .nav-item a {
  color: #999fbb !important;
}

#layout.dark .nav-item a:hover {
  color: #fff !important;
}

#layout.dark .navbar-nav .nav-item a.disabled {
  color: #fff !important;
  opacity: 1 !important;
  background: rgba(100, 180, 255, 0.125) !important;
  box-shadow: none !important;
}

#layout.dark .navbar-nav .nav-item.active {
  background: #0055ff26 !important;
}

.powered-by {
  text-decoration: none !important;
}

.pull-right {
  float: right;
  display: inline-block;
}

@media (min-width: 991px) {
  .mobile-nav-container {
    width: 100%;
  }
}

#copyright {
  min-width: 180px;
}

#main-nav.fade-in .nav-item {
  opacity: 0 !important;
  transition: all 1s;
}

#main-nav.faded-in .nav-item {
  opacity: 1 !important;
}

#main-nav.fade-in {
  top: 0px !important;
  left: 0px !important;
}
#main-nav.faded-in {
  top: 0px !important;
}

#main-content.fade-in {
  opacity: 0;
  transition: all 0.5s;
}
#main-content.faded-in {
  opacity: 1;
}
</style>

<style>
/* Posthog */
.footer-branding {
  display: none !important;
}

/* Crisp */
#crisp-chatbox {
  z-index: 99 !important;
}
</style>

<script>
import WorkspaceNav from "./WorkspaceNav.vue";
export default {
  components: { WorkspaceNav },
  name: "Layout",
  data() {
    return {
      user: null,
      config: {
        siteName: "",
      },
      loaded: false,
      htmlClass: "light",
      fadeIn: false,
    };
  },
  async mounted() {
    console.log("Current Route: " + this.currentRoute());
    if (window != null && process.env.ENVIRONMENT != "development") {
      console.log = function () {};
    }

    this.config = useRuntimeConfig().public.CONFIG;
    // this.user = this.$store.state.user.user;

    var htmlClass = "light";
    if (process.client) {
      htmlClass = localStorage.getItem("htmlClass");

      if (htmlClass == "dark") {
        this.htmlClass = "dark";
      } else if (this.defaultMode == "dark") {
        this.htmlClass = "dark";
      }
    }
    await new Promise((resolve) => setTimeout(resolve, 500)); // wait before fading in nav

    this.loaded = true;
    this.fadeIn = true;
  },
  head() {
    return {
      htmlAttrs: {
        class: this.htmlClass,
      },
      script: [
        {
          innerHTML: this.getJavascript(),
          type: "text/javascript",
          body: true,
        },
      ],
      __dangerouslyDisableSanitizers: ["script"],
    };
  },
  props: ["defaultMode", "options"],
  computed: {},
  methods: {
    getJavascript() {
      if (this.currentRoute().includes("editor") == false) {
        return process.env.JAVASCRIPT_INJECTION;
      }
    },
    toggleDarkMode() {
      if (this.htmlClass == "light") {
        this.htmlClass = "dark";
      } else {
        this.htmlClass = "light";
      }
      if (process.client) {
        localStorage.setItem("htmlClass", this.htmlClass);
      }
    },
    getYear() {
      return new Date().getFullYear(); // i.e. 2022
    },
    getDynamicPageTitle() {
      return "";
    },
    siteName() {
      return "RenderAI";
    },
    hasPermission(input) {
      if (
        this.$store.state.user.user != null &&
        this.$store.state.user.user.permissions != null &&
        this.$store.state.user.user.permissions.features != null &&
        this.$store.state.user.user.permissions.features.includes("tts")
      ) {
        return true;
      } else {
        return false;
      }
    },
    currentRoute() {
      // nuxt2 code
      // return this.$route.name || "";
      //
      // nuxt3 code
      const { currentRoute } = useRouter();
      const routeName = currentRoute.value.name;
      return routeName;
    },
  },
};
</script>
