<template>
  <div>
    <b-modal
      v-if="user != null && user.email != null"
      hide-footer
      :title="modalTitle"
      v-model="showWorkspaceModal"
      id="workspace-editor-modal"
    >
      <b-form-group
        id="fieldset-1"
        description="Choose a name for your workspace."
        label="Name"
        label-for="input-1"
      >
        <b-form-input
          id="workspace-name"
          autocomplete="off"
          v-model="workspaceName"
          :placeholder="'My Project'"
          trim
        ></b-form-input>
      </b-form-group>
      <b-form-group
        class="w-100"
        id="fieldset-2"
        label="Color"
        label-for="input-2"
      >
        <b-form-input
          id="workspace-color"
          v-model="workspaceColor"
          :placeholder="'#000'"
          trim
          type="color"
        ></b-form-input>
        <div align="center" class="w-100 d-block mt-4 pt-2">
          <div
            @click="workspaceColor = color"
            v-for="(color, index) in colorOptions"
            :key="index"
            class="colorOption raised justify-content-center"
            :class="{ 'opacity-100 active': color == workspaceColor }"
            :style="{ backgroundColor: color + ' !important' }"
          >
            <b-icon-check
              v-if="color == workspaceColor"
              style="display: inline-block; color: #fff"
              :class="{ 'color-checkmark-white': color != '#ffffff' }"
              class="m-auto color-checkmark"
            ></b-icon-check>
            <b-icon-check
              v-else
              style="display: inline; color: #fff !important; opacity: 0"
              class="color-checkmark"
            ></b-icon-check>
          </div>
          <br /><br />
        </div>
      </b-form-group>

      <b-form-group
        v-if="workspaceId != null"
        class="w-100"
        id="fieldset-3"
        label="Delete?"
        label-for="input-3"
      >
        <b-form-checkbox v-model="workspaceDeleted" name="check-button" switch>
          Delete this workspace?
          <span class="opacity-50 text-capitalize">{{ workspaceDeleted }}</span>
        </b-form-checkbox>
      </b-form-group>

      <div
        @click="saveWorkspace()"
        :class="{
          'disabled opacity-50 btn-outline-primary':
            workspaceName == null || workspaceName == '',
          'opacity-100 btn-primary':
            workspaceName != null && workspaceName != '',
        }"
        class="btn btn-block"
      >
        <span v-if="workspaceName != '' && workspaceName != null">Save</span>
        <span v-else>Enter a name to continue</span>
      </div>
    </b-modal>
  </div>
</template>

<style scoped>
.colorOption {
  opacity: 0.5;
  transition: all 0.3s;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  border: 1px solid #55555555;
  display: inline-block;
  margin: 8px;
}
.color-checkmark {
  color: #000 !important;
  fill: #000 !important;
}

.color-checkmark-white {
  color: #fff !important;
  fill: #fff !important;
}

@media (max-width: 991px) {
  .colorOption {
    width: 50px;
    height: 50px;
  }
  .color-checkmark {
    position: relative;
    font-size: 200%;
    top: 8px !important;
  }
}
.colorOption:hover,
.colorOption.active {
  opacity: 1;
  border-radius: 3px;
  border-color: #000 !important;
}

.btn.disabled {
  cursor: not-allowed !important;
}
</style>

<script>
const { v4: uuidv4 } = import.meta.glob("./node_modules/uuid");

export default {
  name: "WorkspaceEditor",
  data() {
    return {
      modalTitle: null,
      loadingToast: null,
      workspaceId: null,
      workspaceDeleted: false,
      showWorkspaceModal: false,
      workspaceName: null,
      uuid: null,
      workspaceColor: "#ff00ae",
      colorOptions: [
        "#000000", // black
        "#D63230", // orangered
        "#F39237", // auburn
        "#ffb703", // gold
        "#93E9BE", // seafoam
        "#007bff", // primary blue
        "#7b42ff", // purple
        "#ff00ae", // hot pink
        "#ffafcc", // light pink
        "#ffffff", // white
      ],
    };
  },
  async mounted() {
    this.getRandomColor();
    var self = this;
    this.storeSubscription = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "workspace/showAddWorkspaceModal") {
        console.log("Editing / Adding Workspace...");
        console.log(mutation);
        this.reset();
        if (mutation.payload.title != null) {
          self.workspaceName = mutation.payload.title;
        }
        if (mutation.payload.color != null) {
          self.workspaceColor = mutation.payload.color;
        }
        if (mutation.payload.uuidv4 != null) {
          self.uuid = mutation.payload.uuidv4;
        }

        if (mutation.payload.id != null) {
          self.workspaceId = mutation.payload.id;
        } else {
          self.workspaceId = null;
        }

        self.modalTitle = self.getTitle();

        this.showWorkspaceModal = true;
      }
    });
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
  },
  methods: {
    getTitle() {
      if (this.workspaceName == null) {
        return "New Workspace";
      } else {
        return "Edit Workspace";
      }
    },

    async saveWorkspace() {
      var workspace = {
        title: this.workspaceName,
        color: this.workspaceColor,
        uuidv4: this.uuid,
        deleted: this.workspaceDeleted,
      };

      this.loadingToast = null;
      this.loadingToast = this.$toast.show("Saving...", { duration: 3000 });
      try {
        var token = this.user?.jwt?.raw;

        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };
        var self = this;
        await this.$axios
          .$post(
            "/api/workspace-upsert",
            {
              event: {
                workspace: workspace,
              },
            },
            config
          )
          .then(function (response) {
            // console.log(response)
            self.loadingToast.goAway(100);
            self.$toast.show("Saved.", { duration: 3000 });
            self.$store.commit("workspace/add", workspace);
            if (workspace.deleted == true) {
              self.$store.commit("workspace/select", {
                title: "Personal Workspace",
                uuidv4: null,
                color: "#000000",
              });
            } else {
              self.$store.commit("workspace/select", workspace);
            }
            self.$bvModal.hide("workspace-editor-modal");
          })
          .catch(function (error) {
            self.loadingToast.goAway(100);
            self.$toast.show("Something went wrong.", { duration: 3000 });
            console.log(error);
          });
      } catch (err) {
        console.log(err);
      }
    },
    reset() {
      this.getRandomColor();
      this.uuid = uuidv4();
      this.workspaceName = null;
      this.workspaceDeleted = false;
    },
    getRandomColor() {
      const colors = this.colorOptions;
      const random = Math.floor(Math.random() * colors.length);
      this.workspaceColor = colors[random];
    },
  },
};
</script>
