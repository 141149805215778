<template>
  <div class="text-left">
    <BButton
      class="raised"
      style="border-radius: 7px !important"
      @click="showMobileNavModal = true"
      id="mobile-nav-toggle"
      variant="neutral"
    >
      <b-icon-three-dots></b-icon-three-dots>
    </BButton>
    <Auth class="pull-right auth-button" style="z-index: 99 !important"></Auth>
    <BModal
      id="mobile-nav"
      title="Navigation"
      hide-footer
      v-model="showMobileNavModal"
    >
      <div
        v-if="user != null && user.email != null"
        style="transform: scale(0.8)"
      >
        <p align="center" class="mb-2 mt-0" style="opacity: 0.5">
          You are signed in as
        </p>
        <p class="mx-auto mb-3 w-100 text-center">{{ user?.email }}</p>
      </div>
      <WorkspaceNav
        :truncate="'120px !important'"
        :fullWidth="true"
        class="d-inline-block d-lg-none w-100"
        style="position: relative; left: -8px; top: -5px; max-height: 44px"
        v-if="user != null && user.email != null"
      ></WorkspaceNav>
      <div class="text-left" id="mobile-nav-buttons">
        <div v-if="showAdminLinks == false">
          <BButton
            v-if="user != null && user.role == 'ADMIN'"
            @click="showAdminLinks = true"
            variant="neutral"
            id="admin-button"
            class="btn-block"
          >
            <b-icon-card-list font-scale=".95"></b-icon-card-list>Admin
          </BButton>

          <BButton :href="'/'" variant="neutral" class="btn-block text-left">
            <b-icon-house font-scale=".95"></b-icon-house>Home
          </BButton>
          <BButton
            :href="'/prompts'"
            variant="neutral"
            class="btn-block text-left"
          >
            <b-icon-code-square font-scale=".95"></b-icon-code-square>Prompts
          </BButton>
          <BButton
            :href="'/docs'"
            variant="neutral"
            class="btn-block text-left"
          >
            <b-icon-image font-scale=".95"></b-icon-image>Documents
          </BButton>
          <BButton
            :href="'/images'"
            variant="neutral"
            class="btn-block text-left"
          >
            <b-icon-image font-scale=".95"></b-icon-image>Images
          </BButton>
          <BButton
            v-if="false"
            :href="'/deepfakes'"
            variant="neutral"
            class="btn-block text-left"
          >
            <b-icon-person font-scale=".95"></b-icon-person>Deepfakes</BButton
          >
          <BButton
            :href="'/text-to-speech'"
            variant="neutral"
            class="btn-block text-left"
          >
            <b-icon-soundwave font-scale=".95"></b-icon-soundwave
            >Speech</BButton
          >

          <BButton
            :href="'/pricing'"
            variant="neutral"
            v-if="user == null || user.email == null"
            class="btn-block text-left"
            ><b-icon-credit-card font-scale=".95"></b-icon-credit-card
            >Pricing</BButton
          >
          <BButton
            v-if="user != null && user.email != null"
            href="/billing"
            variant="neutral"
            class="btn-block text-left"
            ><b-icon-file-earmark-text
              font-scale=".95"
            ></b-icon-file-earmark-text
            >Billing
          </BButton>
          <BButton href="/help" variant="neutral" class="btn-block text-left"
            ><b-icon-journal-code font-scale=".95"></b-icon-journal-code>Help
          </BButton>
          <div
            v-if="false == true && config != null && config.nav != null"
            class="my-2 d-inline-block w-100"
          >
            <BButton
              v-for="(link, index) in config.nav"
              :key="index"
              :href="link.url"
              target="_blank"
              variant="neutral"
              class="btn-block text-danger text-left"
            >
              <b-icon-link
                style="margin-right: 5px !important"
                font-scale=".95"
              ></b-icon-link>
              {{ link.label }}
            </BButton>
          </div>
          <BButton
            v-if="user != null && user.email != null"
            @click="signOut()"
            variant="neutral"
            class="btn-block text-danger text-left"
          >
            <b-icon-lock font-scale=".95"></b-icon-lock>Sign Out
          </BButton>
          <BButton
            v-else
            id="sign-in-button"
            variant="neutral"
            @click="signIn"
            class="btn-block text-primary text-left"
          >
            <b-icon-arrow-right font-scale=".95"></b-icon-arrow-right>Sign Up /
            Sign In
          </BButton>
          <iframe
            src="https://status.renderai.com/badge?theme=dark"
            class="embedded-status d-inline-block d-md-none"
            width="auto"
            height="30"
            frameborder="0"
            scrolling="no"
          ></iframe>
        </div>
        <div v-if="showAdminLinks == true">
          <BButton
            v-if="user != null && user.role == 'ADMIN'"
            @click="showAdminLinks = false"
            variant="secondary"
            id="admin-button"
            class="btn-block active"
          >
            <span class="text-light label d-inline-block"
              ><b-icon-card-list font-scale=".95"></b-icon-card-list>Admin</span
            >
            <b-icon-x-circle
              style="float: right; margin-top: 2px; margin-right: -5px"
              class="text-light"
            ></b-icon-x-circle>
          </BButton>
          <div
            class="mt-2"
            v-if="
              config != null &&
              config.admin != null &&
              config.admin.links != null
            "
          >
            <BButton
              v-for="(link, index) in config.admin.links"
              :key="index"
              :href="link.url"
              target="_blank"
              variant="neutral"
              class="btn-block text-danger text-left"
            >
              <b-icon-link
                style="margin-right: 5px !important"
                font-scale=".95"
              ></b-icon-link>
              {{ link.label }}
            </BButton>
          </div>
        </div>
      </div>
    </BModal>
  </div>
</template>

<style>
#mobile-nav .modal-content {
  z-index: 999 !important;
}
</style>

<style scoped>
#mobile-nav .workspace-dropdown {
  max-height: auto !important;
}
#mobile-nav .truncate {
  margin-top: -3px !important;
}

#mobile-nav-toggle {
  float: right;
  box-shadow: 0px 7px 7px rgba(0, 50, 150, 0.1);
}

#admin-button {
  text-align: left;
  transition: 0.3s !important;
}
#admin-button.active .label {
  margin: auto !important;
}
.auth-button {
  float: right;
  margin-right: -47px;
  pointer-events: none;
}

#mobile-nav-buttons .btn {
  color: #434b7d !important;
}
#mobile-nav-buttons .btn:hover {
  color: #000 !important;
  box-shadow: 0px 7px 7px rgba(0, 50, 150, 0.1);
}

#mobile-nav-buttons .btn svg {
  opacity: 0.5;
  margin-right: 10px;
  position: relative;
  top: -1px !important;
}
#layout.dark #mobile-nav-toggle {
  color: #fff !important;
  opacity: 1 !important;
  border: 1px solid rgba(200, 200, 255, 0.3);
  box-shadow: rgba(200, 200, 255, 0.15) 0px 4px 20px !important;
}
#mobile-nav .embedded-status {
  background: #333;
  border-radius: 5px;
  display: inline-block;
  max-width: 100%;
  width: calc(100% - 30px);
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;
  pointer-events: none;
}
@media (max-width: 991px) {
  #mobile-nav .embedded-status {
    max-width: calc(100% - 30px);
  }
}

.btn-block {
  display: block;
  width: 100%;
  text-align: left;
  padding: 10px;
}
</style>

<script setup>
const { show } = useModal();
</script>

<script>
export default {
  name: "MobileNav",
  data() {
    return {
      showAdminLinks: false,
      showMobileNavModal: false,
      user: null,
      config: null,
    };
  },
  async mounted() {
    this.config = useRuntimeConfig().public.CONFIG;
    this.getUser();
  },
  methods: {
    showModal() {
      this.showMobileNavModal = true;
    },
    signOut() {
      this.user = null;
      this.userToken = null;
      this.$store.commit("user/signOut", "");
      this.$toast.show("You have been signed out.", { duration: 3000 });
      this.$root.$emit("bv::hide::modal", "auth-modal", null);
      window.location.href = "/";
    },
    getUser() {
      if (process.client) {
        this.$store.commit("user/load", "");
      }
      this.user = this.$store.state.user?.user;
      if (this.user != null) {
        var decodedUser = null;
        if (this.userToken != null) {
          decodedUser = this.jwtDecode(this.userToken, alert);
          console.log(decodedUser);
          if (decodedUser != null) {
            decodedUser = {
              jwt: decodedUser,
              email: decodedUser.payload.user.email,
            };
            this.user = decodedUser;
            this.$store.commit("user/signIn", decodedUser);
            // window.location.href = '/'
          }
        }
      }
    },
    signIn() {
      this.$root.$emit("bv::show::modal", "signin-modal", null);
      this.$root.$emit("bv::hide::modal", "mobile-nav", null);
    },
    deleteAllImages() {
      this.$store.commit("images/removeAll", true);
      this.$toast.show("All images deleted.", { duration: 3000 });
      this.$root.$emit("bv::hide::modal", "mobile-nav", null);
    },
  },
};
</script>
