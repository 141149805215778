<template>
  <b-nav-item-dropdown
    :key="key"
    v-if="loading == false"
    class="border ml-2 pr-2 pl-1 br-5 workspace-dropdown"
    style="
      box-shadow: none !important;
      overflow: visible !important;
      position: relative;
      left: 10px;
      top: -7px;
      float: left;
    "
    :menu-class="getMenuClass()"
  >
    <!-- Using 'button-content' slot -->
    <template #button-content>
      <span
        class="d-none d-md-inline text-dark"
        v-if="currentWorkspace != null"
      >
        <b-icon-circle-fill
          v-if="currentWorkspace.uuidv4 != null"
          :style="{ fill: currentWorkspace.color }"
          class="mr-2 raised"
        ></b-icon-circle-fill>
        <b-icon-person-circle v-else class="mr-2"></b-icon-person-circle>
        {{ currentWorkspace.title }}
      </span>
      <span class="d-inline-block d-md-none truncate text-dark">
        <b-icon-circle-fill
          v-if="currentWorkspace.uuidv4 != null"
          :style="{ fill: currentWorkspace.color }"
          class="mr-2 raised"
        ></b-icon-circle-fill>
        <b-icon-person-circle v-else class="mr-2 raised"></b-icon-person-circle>
        {{ currentWorkspace.title }}</span
      >
      <b-icon-caret-down-fill
        class="workspace-caret"
        style="width: 10px !important"
      ></b-icon-caret-down-fill>
    </template>
    <b-dropdown-item
      @click="showAddWorkspaceModal"
      class="new-workspace-button"
      href="#"
      style="cursor: not-allowed !important"
    >
      <b-icon-gear
        class="workspace-settings-icon"
        style="opacity: 0 !important"
      ></b-icon-gear>
      <b-icon-plus-square class="mr-2"></b-icon-plus-square> New
      Workspace</b-dropdown-item
    >
    <b-dropdown-item class="text-primary" href="#" @click="selectWorkspace()">
      <b-icon-gear
        class="workspace-settings-icon"
        style="opacity: 0 !important"
      ></b-icon-gear>
      <b-icon-person-circle class="mr-2"></b-icon-person-circle> Personal
      Workspace</b-dropdown-item
    >
    <b-dropdown-item
      @click="selectWorkspace(workspace)"
      v-for="(workspace, index) in workspaces"
      :class="{ active: workspace.uuidv4 == currentWorkspace?.uuidv4 }"
      :key="index"
      class="text-primary dropdown-fullwidth"
      href="#"
    >
      <b-icon-circle-fill
        :style="{ fill: workspace.color }"
        class="mr-2 raised"
      >
      </b-icon-circle-fill>
      <b-icon-gear
        @click="showAddWorkspaceModal(workspace)"
        class="workspace-settings-icon"
      ></b-icon-gear>
      {{ workspace.title }} &nbsp;
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<style scoped>
.workspace-settings-icon {
  opacity: 0.5 !important;
  display: inline !important;
  float: left;
  position: relative;
  left: -10px;
  top: 0px;
  transition: all 0.3s;
}

.workspace-settings-icon:hover {
  opacity: 1 !important;
}

.dropdown-toggle {
  outline: none !important;
}
.min-width-100 {
  width: auto !important;
  display: inline-block !important;
  min-width: 100% !important;
}
.new-workspace-button {
  opacity: 0.5;
  transition: all 0.3s;
}

.new-workspace-button:hover {
  opacity: 1;
}

.workspace-dropdown * {
  box-shadow: none !important;
}
.workspace-caret {
  position: relative;
  left: 5px;
  top: 0px !important;
}

@media (max-width: 991px) {
  .workspace-caret {
    position: relative;
    left: 5px;
    top: -3px !important;
  }
}

.workspace-dropdown .active > .dropdown-item {
  color: #007bff !important;
}
.truncate {
  display: inline-block;
  width: var(--truncated-width) !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

<script>
const { v4: uuidv4 } = import.meta.glob("./node_modules/uuid");

export default {
  name: "WorkspaceNav",
  data() {
    return {
      key: "123",
      loading: true,
      workspaces: null,
      currentWorkspace: null,
    };
  },
  props: ["truncate", "fullWidth"],
  async mounted() {
    this.key = uuidv4();
    var self = this;
    this.loading = false;
    this.getWorkspaces();
    this.getCurrentWorkspace();
    this.storeSubscription = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "workspace/select") {
        self.loading = true;
        self.getWorkspaces();
        self.getCurrentWorkspace();
        self.key = uuidv4();
        self.loading = false;
        console.log("Reloading workspace...");
      }
    });
  },

  methods: {
    getCurrentWorkspace() {
      //  this.currentWorkspace = this.$store.state.workspace.currentWorkspace
      this.currentWorkspace =
        this.$store.getters["workspace/getCurrentWorkspace"];
      return this.$store.getters["workspace/getCurrentWorkspace"];
    },
    async getWorkspaces() {
      // get workspaces from store:
      // this.workspaces = this.$store.state.workspace.list

      var self = this;
      var token = this.user?.jwt?.raw;

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      await this.$axios
        .$post(
          "/api/workspace-browse",
          {
            event: {},
          },
          config
        )
        .then(function (response) {
          console.log(response);
          self.workspaces = response;
        })
        .catch(function (error) {
          console.log(error);
          self.loading = false;
        });
    },
    getMenuClass() {
      if (this.fullWidth == true) {
        return "w-100";
      } else {
        return "";
      }
    },
    selectWorkspace(id) {
      if (id == null) {
        id = {
          title: "Personal Workspace",
          uuidv4: null,
          color: "#000000",
        };
      }
      console.log("Selected workspace: " + id);
      this.$store.commit("workspace/select", id);
    },
    showAddWorkspaceModal(input) {
      this.$bvModal.hide("mobile-nav");
      this.$store.commit("workspace/showAddWorkspaceModal", input);
    },
  },

  computed: {
    user() {
      return this.$store.state.user.user;
    },
    cssVars() {
      return {
        "--truncated-width": this.truncate || "50px !important",
      };
    },
  },
};
</script>
