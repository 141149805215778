<template>
  <div id="auth-toggle">
    <BButton
      v-if="user != null && user.email != null"
      @click="signInToggle"
      variant="neutral"
      style="margin-left: 0px !important"
      class="d-inline-block"
    >
      <div v-if="user == null || user.email == null">
        <b-icon-person></b-icon-person>
        <div class="d-inline ml-2">Sign In</div>
      </div>
      <b-avatar
        v-else
        :text="getAvatarText()"
        size="22px"
        style="margin-top: -4px"
      ></b-avatar>
    </BButton>
    <b-modal id="signout-modal" title="Account" hide-footer>
      <p align="center" style="opacity: 0.5">You are signed in as</p>
      <p class="mx-auto mb-4 w-100 text-center">{{ user?.email }}</p>
      <BButton
        v-if="user != null && user.email != null"
        variant="outline-primary"
        class="btn-block"
        :href="'/billing'"
        >Billing
        <b-icon-file-earmark-text
          class="my-0 ml-2 d-inline"
        ></b-icon-file-earmark-text
      ></BButton>
      <BButton
        v-if="user != null && user.email != null"
        @click="signOut()"
        variant="outline-danger"
        class="btn-block"
        >Sign Out <b-icon-lock class="ml-2"></b-icon-lock
      ></BButton>
    </b-modal>
    <b-modal
      ref="signin-modal"
      id="signin-modal"
      style="z-index: 99999999999999999 !important"
      title="Sign In to Continue"
      hide-footer
    >
      <b-form-input
        class="mb-3"
        id="email"
        v-model="email"
        aria-autocomplete="off"
        autocomplete="off"
        placeholder="tony.stark@example.com"
      ></b-form-input>

      <BButton
        @click="requestSignInCode()"
        variant="outline-primary"
        :disabled="email == null || email == ''"
        class="btn-block"
        ><span v-if="email == null || email == ''"
          >Enter your email to continue </span
        ><span v-else>Request Code</span
        ><b-icon-arrow-right class="ml-2"></b-icon-arrow-right
      ></BButton>
      <BButton
        v-if="email != null && email != ''"
        @click="openVerificationModal()"
        variant="outline-neutral"
        id="auth-have-code-button"
        :disabled="email == null || email == ''"
        class="btn-block"
        >Already have a code?</BButton
      >
    </b-modal>
    <b-modal
      id="signin-verification-modal"
      title="Finish Signing In"
      hide-footer
    >
      <b-input-group class="mb-3" size="lg">
        <template #prepend>
          <b-input-group-text size="lg"
            ><strong class="text-primary">#</strong></b-input-group-text
          >
        </template>
        <b-form-input
          v-model="code"
          aria-autocomplete="off"
          autocomplete="off"
          placeholder="THX1138"
          id="code"
        ></b-form-input>
      </b-input-group>

      <BButton
        @click="verifySignInCode()"
        variant="outline-primary"
        id="auth-submit-code-button"
        :disabled="code == null || code == ''"
        class="btn-block"
        ><span v-if="code == null || code == ''"
          >Enter the code from your email</span
        ><span v-else>Continue</span>
        <b-icon-arrow-right class="ml-2"></b-icon-arrow-right
      ></BButton>
    </b-modal>
  </div>
</template>

<style>
#layout.dark #auth-toggle {
  color: #fff !important;
}
.b-avatar {
  background: #a9b2d0 !important;
}
</style>

<script>
export default {
  name: "Auth",
  props: [],
  data() {
    return {
      userToken: null,
      user: null,
      email: null,
      code: null,
      checkoutResponse: null,
    };
  },
  async mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const userToken = urlParams.get("t");
    if (userToken != null) {
      this.userToken = userToken;
    }
    this.getUser();
  },
  methods: {
    openVerificationModal() {
      this.$root.$emit("bv::hide::modal", "signin-modal", null);
      this.$root.$emit("bv::show::modal", "signin-verification-modal", null);
    },
    async setUser(response) {
      if (response.body != null) {
        response = response.body;
      }
      console.log("Auth result: \n");
      if (typeof response == "string") {
        response = JSON.parse(response);
      }
      console.log(response.token);
      this.userToken = response.token;
      var decodedUser = null;
      if (this.userToken != null) {
        decodedUser = this.jwtDecode(this.userToken, alert);
        console.log(decodedUser);
        var userToStore = null;
        if (decodedUser != null) {
          userToStore = {
            jwt: decodedUser,
            email: decodedUser.payload?.user,
          };
          this.user = userToStore;
          // this.$store.commit('user/signIn', userToStore) //TODO fixme
        }
      }
    },
    async verifySignInCode() {
      this.$toast.show("Loading...", { duration: 3000 });
      var self = this;
      try {
        const config = {};
        await this.$axios
          .$post(
            "/api/auth-verify-token",
            {
              event: {
                user: {
                  email: self.email,
                },
                code: self.code.trim(),
              },
            },
            config
          )
          .then(async function (response) {
            console.log(response);
            if (response != null) {
              console.log("\nAuthorization code successfully verified.\n");
              console.log(response);
              self.$toast.show("Sign-in successful.", { duration: 3000 });
              self.$root.$emit(
                "bv::hide::modal",
                "signin-verification-modal",
                null
              );

              await self.setUser(response);
              window.location.reload();
            } else {
              self.$toast.error("Something went wrong. Try again.", {
                duration: 3000,
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            self.$toast.error("Something went wrong. Try again.", {
              duration: 3000,
            });
          });
      } catch (err) {
        console.log(err);
      }
    },
    async requestSignInCode() {
      this.$toast.show("Loading...", { duration: 3000 });
      var self = this;
      try {
        const config = {};
        await this.$axios
          .$post(
            "/api/auth-request-token",
            {
              event: {
                user: {
                  email: self.email.toLowerCase(),
                },
              },
            },
            config
          )
          .then(function (response) {
            console.log("\nAuthorization code successfully requested.\n");
            console.log(response);
            self.$root.$emit("bv::hide::modal", "signin-modal", null);
            self.$root.$emit(
              "bv::show::modal",
              "signin-verification-modal",
              null
            );
          })
          .catch(function (error) {
            console.log(error);
          });
      } catch (err) {
        console.log(err);
      }
    },
    getAvatarText() {
      if (this.user != null) {
        var name = this.user?.email;
        if (name != null && name.length > 0) {
          return name[0];
        }
      }
    },
    signInToggle() {
      if (this.user != null && this.user.email != null) {
        this.$bvModal.show("signout-modal");
      } else {
        this.signIn();
      }
    },
    signIn() {
      this.$bvModal.show("signin-modal");
      /*
      var operand = '?'
      if (process.env.AUTH_URL != null && process.env.AUTH_URL.includes('?')) {
        operand = '&'
      }
      var url =
        process.env.AUTH_URL +
        operand +
        'redirect=' +
        encodeURIComponent(window.location.origin)
      console.log('Redirecting to: ' + url)
      window.location.href = url
      */
    },
    signOut() {
      this.user = null;
      this.userToken = null;
      // this.$store.commit('user/signOut', '') //TODO fixme
      this.$toast.show("You have been signed out.", { duration: 3000 });
      this.$root.$emit("bv::hide::modal", "auth-modal", null);
      this.posthog.reset();
      window.location.href = "/";
    },
    getUser() {
      if (process.client) {
        // this.$store.commit('user/load', '') //TODO fixme
      }
      /* // TODO fixme
      this.user = this.$store.state?.user?.user
      if (this.user != null) {
        var decodedUser = null
        if (this.userToken != null) {
          decodedUser = this.jwtDecode(this.userToken, alert)
          console.log(decodedUser)
          if (decodedUser != null) {
            decodedUser = {
              jwt: decodedUser,
              email: decodedUser.payload?.user?.email,
            }


            this.user = decodedUser
            // this.$store.commit('user/signIn', decodedUser) //TODO fixme
            window.location.href = '/app'
          }
        }
      }
      */
    },
    jwtDecode(t, alert) {
      if (t != null) {
        let token = {};
        token.raw = t;
        try {
          token.header = JSON.parse(window.atob(t.split(".")[0]));
          token.payload = JSON.parse(window.atob(t.split(".")[1]));
        } catch (err) {
          if (alert == true) {
            this.$toast.show("⚠️ Sign in failed.", {
              position: "top-center",
              theme: "toasted-primary",
              duration: 3000,
              closeOnSwipe: true,
              className: "toast-custom",
              containerClass: "toast-custom-container",
            });
          }
          console.log(err);
        }
        return token;
      }
    },
  },
};
</script>
