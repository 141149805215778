<template>
  <div></div>
</template>

<style></style>

<script>
export default {
  name: "SupportChat",
  data() {
    return {};
  },
  props: ["user"],
  async mounted() {
    if (window.$crisp != null && this.user != null) {
      console.log("Assigning user ID.");
      window.$crisp.push(["set", "user:email", [this.user.email]]);
    }
  },
  methods: {},
};
</script>
